import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  alerts: any[] = [];


  addAlert(message: string, type: string, duration: number = 5000) {
    const alert = { message, type };
    this.alerts.push(alert);

    setTimeout(() => {
      this.removeAlert(alert);
    }, 5000);
  }

  removeAlert(alert: any) {
    const index = this.alerts.indexOf(alert);
    if (index !== -1) {
      this.alerts.splice(index, 1);
    }
  }
}
